import styled from '@emotion/styled';
import { memo, useCallback, useEffect } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_cross_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import KbarSymbolQuote from '../quote/KbarSymbolQuote';
import { edwinGroupName } from '../WatchListGroupName';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { useMount } from 'react-use';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { Chip, Select } from '@mantine/core';
import { css } from '@emotion/react';
import { FcCalendar } from 'react-icons/fc';
import { edwin_store } from '../../edwin_store';
import { useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import dayAPI from '~/utils/dayAPI';
export const RankingContent = memo(function RankingContent(props) {
    const watchList = useSymbolWatchList(edwinGroupName);
    const value = props.quoteValue;
    const state = useSnapshot(edwin_store);
    const tradedDate = useSnapshot(staticStore).tradedDate;
    const day0 = tradedDate.day0;
    const intraday = tradedDate.intraday;
    const historyDate = tradedDate.days.map(s => s.format('YYYY-MM-DD'));
    useMount(() => {
        useSortSignalrDataStore.sortKey = 'amount';
        edwin_store.rankingDate = dayAPI(day0).format('YYYY-MM-DD');
    });
    //在選擇 即時/歷史 給予對應的mantain使用的選股日期 ------------------------------------------------
    const rankingDateTimeData = useCallback(() => {
        if (state.rankingType === 'history') {
            return historyDate;
        }
        else {
            return [intraday.format('YYYY-MM-DD')];
        }
    }, [JSON.stringify(historyDate), state.rankingType, intraday]);
    const rankingDateData = rankingDateTimeData();
    //在選擇 即時/排行 給予對應的日期 ------------------------------------------------
    useEffect(() => {
        if (state.screenerType === 'realTime') {
            edwin_store.rankingDate = intraday.format('YYYY-MM-DD');
        }
        if (state.screenerType === 'history') {
            edwin_store.rankingDate = rankingDateData[0];
        }
    }, [rankingDateData, state.rankingType, intraday]);
    return (<styleds.container>
        <styleds.selecter>
          <Chip css={css `
              .mantine-hwb60 {
                width: 72px;
                height: 28px;
                background-color: #252931;
              }
            `} checked={state.rankingType === 'realTime'} onChange={() => (edwin_store.rankingType = 'realTime')} size={'xs'} radius='md' color='yellow'>
            即時
          </Chip>
          <Chip css={css `
              .mantine-hwb60 {
                width: 72px;
                height: 28px;
                background-color: #252931;
              }
            `} checked={state.rankingType === 'history'} onChange={() => (edwin_store.rankingType = 'history')} size={'xs'} radius='md' color='yellow'>
            歷史
          </Chip>

          <Select css={css `
              .mantine-58jpum {
                width: 160px;
                background-color: #292c33;
              }
            `} disabled={state.rankingType === 'realTime' ? true : false} icon={<FcCalendar size='24px'/>} defaultValue={rankingDateData[0]} value={state.rankingDate} data={rankingDateData.slice(0, 15)} onChange={(date_) => {
            edwin_store.rankingDate = date_;
        }}/>
        </styleds.selecter>
        <styleds.header>
          <KbarSymbolQuote.header />
        </styleds.header>
        <styleds.body>
          {value?.map((quote, index) => {
            return (<KbarSymbolQuote.body symbolWatchList={watchList} key={index} quote={quote}/>);
        })}
        </styleds.body>
      </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    selecter: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    width: 100%;
    height: 36px;
    gap: 4px;
  `,
    header: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 36px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 2px;
    heigth: calc(100% - 40px);
  `,
};
