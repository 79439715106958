import { MantineProvider } from '@mantine/core';
import { memo } from 'react';
import { useUpdateEffect } from 'react-use';
import { useSnapshot } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { useIntervalNow } from '~/hooks/useIntervalNow';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import IntradayTrendChart from '~/modules/trendChart2';
import { store } from '~/pages/heineken_template/_private/store';
import { indicatorGroup } from './south_initStrategies';
import { south_store } from './south_store';
import { styleds } from './styleds';
import { setPositionInfo } from './south_utils';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { SignalQuoteBrick } from './components/SymbolPositionQuote';
import { instrumentService } from '~/modules/SDK/services/instruments';
export const South_SidePane1 = memo(function South_SidePane1() {
    const colorTheme = useThemeStore(state => state.theme);
    const charting = useSnapshot(store.charting);
    const signalsState = useSnapshot(south_store.signals.store);
    useUpdateEffect(() => {
        south_store.signals.fetchAndUpdate();
    }, [signalsState.from, signalsState.to]);
    useIntervalNow(() => {
        south_store.signals.fetchAndUpdate();
    }, 1000 * 30);
    return (<MantineProvider theme={{ colorScheme: colorTheme }}>
      <styleds.SideBar>
        <TitleSymbolQuote.Default symbol={charting.symbol}/>

        <styleds.IntradayTrendChart>
          <IntradayTrendChart 
    // symbol={charting.symbol}
    instrument={instrumentService.getInstrument(charting.symbol)} ticksSize={10} ticksHeight={16} transparency={0.4} priceTicksSize={9} priceTicksMargin={-15}/>
        </styleds.IntradayTrendChart>

        <styleds.SimpleQuoteListTXF>
          <SimpleQuoteListInteractWithChart data={['TX-1', 'TXAM-1']} chart={store.charting}/>
        </styleds.SimpleQuoteListTXF>

        <styleds.TitleString>南哥量化當沖選股期</styleds.TitleString>

        <styleds.SimpleQuoteListInteract>
          <south_store.signals.DatePicker />
          <SignalQuoteBrick signals={signalsState.data ?? []} chart={store.charting} indicators={indicatorGroup} handleClick={signal => {
            setPositionInfo(signal);
        }}/>
        </styleds.SimpleQuoteListInteract>
      </styleds.SideBar>
    </MantineProvider>);
});
