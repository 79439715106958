import styled from '@emotion/styled';
import { Autocomplete } from '@mantine/core';
import { map } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useAsyncFn, useMount, useUpdateEffect } from 'react-use';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useIntervalNow } from '~/hooks/useIntervalNow';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { SignalWallId } from '~/modules/signal-wall/SignalWallId';
import { removeAfterWhitespace } from '~/modules/symbolPlatform/removeAfterWhitespace';
import { futuresai_store } from '~/pages/futuresai/_private/futuresai_store';
import dayAPI from '~/utils/dayAPI';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
const taiSymbol = [
    '2867',
    '2890',
    '2892',
    '6024',
    '2834',
    '2891',
    '2880',
    '2881',
    '2882',
    '2887',
    '2855',
    '2809',
    '2886',
    '2836',
    '2885',
    '2820',
    '2801',
    '2851',
    '5880',
    '2812',
    '2845',
    '2849',
    '2884',
    '2888',
    '6005',
    '2832',
    '2852',
    '2897',
    '2816',
    '2889',
    '2838',
    '2850',
    '2883',
];
/** //todo: 大單數量篩選 大單金額篩選 價格篩選 商品篩選 */
// 盤中突破日內高點 = 'RT_futuresai_01',
// 盤中突破日內低點 = 'RT_futuresai_02',
// 盤中高預估周轉率 = 'RT_futuresai_03',
// 盤中高預估量比 = 'RT_futuresai_04',
// 盤中創五日新高 = 'RT_futuresai_05',
// 盤中創五日新低 = 'RT_futuresai_06',
export const SignalWall = memo(function SignalWall() {
    const sounds = useSoundStore.getState();
    const signalsStore = futuresai_store.signalWallPage.signals;
    const signalsState = useSnapshot(signalsStore.store);
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const [data, fetch] = useAsyncFn(async () => {
        return signalsStore.fetchAndUpdate();
    }, []);
    useMount(() => {
        signalsStore.toggle({
            query: SignalWallId.盤中突破日內高點,
            displayName: '盤中突破日內高點',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中突破日內低點,
            displayName: '盤中突破日內低點',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中高預估量比,
            displayName: '盤中高預估量比',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中創五日新高,
            displayName: '盤中創五日新高',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中創五日新低,
            displayName: '盤中創五日新低',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中高預估周轉率,
            displayName: '盤中高預估周轉率',
        }, false);
    });
    useIntervalNow(() => {
        fetch();
    }, 1000 * 5);
    useUpdateEffect(() => {
        fetch();
    }, [signalsState.from, signalsState.to, signalsState.sorter]);
    useUpdateEffect(() => {
        signalsStore.update();
    }, [
        signalsState.filter.text,
        signalsState.filter.minVolume,
        signalsState.filter.maxVolume,
        signalsState.filter.minAmount,
        signalsState.filter.maxAmount,
        signalsState.filter.stockFuture,
    ]);
    const adata = signalsState.data.filter(s => s.signal_id === 'qty_alert');
    const [temporaryData, setTemporaryData] = useState(['']);
    //要篩選的股票
    const [filterSymbols, setFilterSymbols] = useState(false);
    //排除金融股
    const [excludeFinancial, setExcludeFinancial] = useState(true);
    //要篩選的方向
    const [filterBS, setFilterBS] = useState('all');
    //要篩選的大單成交金額
    const [filterTickValue, setFilterTickValue] = useState(500);
    //要篩選的大單數量
    const [filterTickQty, setFilterTickQty] = useState(100);
    const filterSymbolGroupName = 'signal_wall_fliter';
    const excludeSymbolGroupName = 'signal_wall_exclude';
    const filterSymbolsState = useSymbolWatchList(filterSymbolGroupName);
    const excludeSymbolsState = useSymbolWatchList(excludeSymbolGroupName);
    const [inputValue, setInputValue] = useState('');
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const symbolNumber = removeAfterWhitespace(inputValue);
            filterSymbolsState.acts.addSymbol(symbolNumber, 20);
            setInputValue('');
        }
    };
    //
    useEffect(() => {
        setTemporaryData(temporaryData);
        sounds.playSoundNotifyDebounced();
    }, [JSON.stringify(adata), sounds, temporaryData]);
    const searchDefaultSymbols = Object.entries(dictionary).map(s => ({
        symbol: s[0],
        name: s[1],
    }));
    return (<classes.container>
      <classes.signalWall.container>
        <classes.signalWall.header>
          <span>時間</span>
          <span>商品</span>
          <span>代號</span>
          <span>方向</span>
          <span>單量</span>
          <span>價格</span>
          <span>幅度</span>
          <span>金額</span>
        </classes.signalWall.header>

        <classes.signalWall.body>
          {map(adata, (datum, key) => {
            /** 單量 */
            const tickQty = datum.signal_data && 'tick_qty' in datum.signal_data
                ? datum.signal_data['tick_qty']
                : 0;
            /** 最新成交價 */
            const close = datum.signal_data && 'close' in datum.signal_data ? datum.signal_data['close'] : 0;
            /** 買進/賣出 */
            const tickBS = datum.signal_data && 'tick_bs' in datum.signal_data
                ? datum.signal_data['tick_bs']
                : '';
            /** 昨收 */
            const prevref = datum.signal_data && 'prev_ref' in datum.signal_data
                ? datum.signal_data['prev_ref']
                : 0;
            /** 成交金額 單:位萬 */
            const tradeValue = tickQty * close * 0.1;
            /** 變動 */
            const changePrecent = ((close - prevref) / prevref) * 100;
            /** 變動符號 */
            const changeSymbol = changePrecent > 0 ? '+' : '';
            /** 篩選:單量 */
            const filterBaseCondition = tradeValue >= filterTickValue && tickQty >= filterTickQty;
            /** 篩選:方向 */
            const filterBSCondition = filterBS !== 'all' ? tickBS === filterBS : true;
            /** 篩選:自訂篩選商品 */
            const filterWatchListSymbols = filterSymbols === true
                ? filterSymbolsState.state.symbolListArray.includes(datum.symbol)
                : true;
            /** 篩選:金融股 */
            const excludeFinancialSymbols = excludeFinancial === true ? !taiSymbol.includes(datum.symbol) : true;
            /** 本機當下時間 */
            const currentTime = dayAPI();
            /** 大單資料時間 */
            const dataTime = dayAPI(datum.datetime);
            /** 最新30秒的大單資料 */
            const diffTime = currentTime.diff(dataTime, 'seconds') < 30;
            if (filterBaseCondition &&
                filterBSCondition &&
                filterWatchListSymbols &&
                excludeFinancialSymbols) {
                return (<classes.signalWall.message key={key} bs={tickBS} newMessage={diffTime}>
                  <span>{dayAPI(datum.datetime).format('HH:mm:ss')}</span>
                  <span>{dictionary[datum.symbol]}</span>
                  <span>{datum.symbol}</span>
                  <span>{tickBS === 'b' ? '買進' : '賣出'}</span>
                  <span>{tickQty}張</span>
                  <classes.signalWall.price quote={changePrecent}>
                    {close?.toFixed(2)}
                  </classes.signalWall.price>
                  <classes.signalWall.price quote={changePrecent}>
                    {changeSymbol}
                    {changePrecent.toFixed(2)}%
                  </classes.signalWall.price>
                  <span>{tradeValue.toFixed(0)}萬</span>
                </classes.signalWall.message>);
            }
        })}
        </classes.signalWall.body>
      </classes.signalWall.container>
      <classes.dashboard.container>
        <classes.dashboard.board>
          <span>目前篩選大單金額: {filterTickValue}</span>
          <div onClick={() => setFilterTickValue(100)}>100萬</div>
          <div onClick={() => setFilterTickValue(300)}>300萬</div>
          <div onClick={() => setFilterTickValue(500)}>500萬</div>
          <div onClick={() => setFilterTickValue(1000)}>1000萬</div>
          <div onClick={() => setFilterTickValue(2000)}>2000萬</div>
        </classes.dashboard.board>

        <classes.dashboard.board>
          <span>目前篩選大單張數: {filterTickQty}</span>
          <div onClick={() => setFilterTickQty(10)}>10張</div>
          <div onClick={() => setFilterTickQty(50)}>50張</div>
          <div onClick={() => setFilterTickQty(100)}>100張</div>
          <div onClick={() => setFilterTickQty(200)}>200張</div>
          <div onClick={() => setFilterTickQty(300)}>300張</div>
          <div onClick={() => setFilterTickQty(400)}>400張</div>
        </classes.dashboard.board>

        <classes.dashboard.board>
          <span>目前篩選方向: {filterBS}</span>
          <div onClick={() => setFilterBS('all')}>全部</div>
          <div onClick={() => setFilterBS('b')}>買進</div>
          <div onClick={() => setFilterBS('s')}>賣出</div>
        </classes.dashboard.board>

        <classes.dashboard.board>
          <span>排除名單</span>
          <div onClick={() => setExcludeFinancial(!excludeFinancial)}>
            {excludeFinancial ? 'V' : ''}排除金融股
          </div>
        </classes.dashboard.board>

        <classes.dashboard.board>
          <span>目前篩選選商品:</span>
          <div onClick={() => setFilterSymbols(!filterSymbols)}>
            狀態:{filterSymbols === true ? '啟用' : '停用'}
          </div>

          <Autocomplete icon={<AiOutlineSearch size={22}/>} placeholder='搜尋商品代號' data={searchDefaultSymbols.map(s => s.symbol + ' ' + s.name)} variant='unstyled' limit={100} maxDropdownHeight={200} value={inputValue} onChange={(value) => setInputValue(value)} onItemSubmit={item => {
            const symbolNumber = removeAfterWhitespace(inputValue);
            filterSymbolsState.acts.addSymbol(symbolNumber, 20);
            setInputValue('');
        }} onKeyDown={handleKeyDown}/>
          {filterSymbolsState.state.symbolListArray.map(s => {
            return (<div key={s} onClick={() => filterSymbolsState.acts.removeSymbol(s)}>
                {s}
              </div>);
        })}
        </classes.dashboard.board>
      </classes.dashboard.container>
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_horizontal_cross_center};
  `,
    signalWall: {
        container: styled.div `
      ${fill_vertical_cross_center};
      font-size: 13px;
      transition: 0.3s;
    `,
        header: styled.div `
      ${fill_horizontal_cross_center};
      display: grid;
      grid-template-columns: 10% 10% 8% 8% 8% 10% 10% 10%;
      height: 32px;
      & > * {
        ${fill_horizontal_all_center};
      }
    `,
        body: styled.div `
      ${fill_vertical_cross_center};
      ${scrollbar2Css};
      height: calc(100% - 32px);
      gap: 1px;
    `,
        message: styled.div `
      ${fill_horizontal_cross_center};
      display: grid;
      grid-template-columns: 10% 10% 8% 8% 8% 10% 10% 10%;
      width: 100%;
      height: 32px;
      flex-shrink: 0;
      background-color: ${props => props.bs === 'b' ? '#581e1e' : props.bs === 's' ? '#0e5b1e' : ''}${props => !props.newMessage ? '66' : null};
      & > * {
        ${fill_horizontal_all_center};
        white-space: nowrap;
      }
      border-radius: 2px;
    `,
        price: styled.div `
      color: ${props => (props.quote > 0 ? '#ff2222' : props.quote < 0 ? '#22ff22' : '')};
    `,
    },
    dashboard: {
        container: styled.div `
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: 200px 200px 200px;
      font-size: 13px;
    `,
        board: styled.div `
      ${fill_vertical_cross_center};
      align-items: start;
      & > div {
        cursor: pointer;
      }
    `,
    },
};
